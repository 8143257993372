.IconText {
  position: relative;

  img {
    width: 77px;
    height: 77px;
  }

  span {
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 65px);
    background: rgba(211, 85, 194, 0.5);
    filter: blur(50px);
    width: 80px;
    height: 80px;
    display: block;
  }
}
