.Laptop {
  position: relative;

  img {
    display: block;
  }

  iframe {
    position: absolute;
  }
}
