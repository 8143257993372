#Quotes {
  background: #3b1c4e;

  .card {
    max-width: 100% !important;
    background-color: #190e2b;
    padding: 42px 23px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 344px !important;
    margin: 7rem auto 0;
    position: relative;
    border-radius: 6px;

    @media only screen and (max-width: 420px) {
      width: 80%;
    }

    &__image {
      width: 94px;
      height: 94px;
      margin: 0 auto;
      border-radius: 100%;
      text-align: center;
      position: relative;
      top: -7rem;
      transform: scale(1.5);
      padding: 1em;
      background: linear-gradient(301.46deg, #EF3355 30.86%, #C766F4 73.16%);

      img {
        width: 84px;
        height: 84px;
        display: block;
        margin: 0 auto;
        border-radius: 100%;
        position: relative;
        top: -11px;
        left: -11px;
      }
    }

    &__text {
      font-family: 'Karla', sans-serif;
      margin-top: -5rem;
      position: relative;
      z-index: 1;

      &--name {
        background-clip: unset;
        background-image: none;
        color: #f8e3ff;
        font-size: 21px;
        font-weight: 800;
        line-height: 25px;
      }

      &--description {
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
        color: #f8e3ff;
        height: 50px;
      }

      &--icon {
        display: block;
        width: 100%;
        height: 4rem;
        margin: 20px 0 14px;
      }

      &--quote-style {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #f8e3ff;
        text-align: center;
        height: 200px;

        @media (max-width: 639px) {
          height: 240px;
          text-align: center;
        }
      }
    }
  }

  .slick-slider {
    padding-top: 60px;
    padding-bottom: 60px;

    .slick-list {
      padding: 0 0 4rem;
    }

    .slick-track {
      .slick-slide {
        &:nth-child(3n+1) {
          .card__text--icon {
            background: url('./images/quote-icon-purple.svg') no-repeat 50%;
          }
        }

        &:nth-child(3n+2) {
          .card__text--icon {
            background: url('./images/quote-icon-pink.svg') no-repeat 50%;
          }
        }

        &:nth-child(3n+3) {
          .card__text--icon {
            background: url('./images/quote-icon-red.svg') no-repeat 50%;
          }
        }
      }
    }

    .slick-dots {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 36px;
      bottom: 1.5rem;

      li {
        width: 8px;
        height: 8px;
        border-radius: 100px;
        margin: 0 8px;
        transition: width .2s ease;

        &:nth-child(3n+1) {
          background: #C665F4;
        }

        &:nth-child(3n+2) {
          background: #DA4DA8;
        }

        &:nth-child(3n+3) {
          background: #EF3254;
        }

        &.slick-active {
          width: 45px;
        }

        button {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
