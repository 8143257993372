.RobotText {
  position: absolute;
  align-items: center;
  text-align: center;
  max-width: 200px;

  h2 {
    display: inline-block;
    font-size: 2.5rem;
  }

  p {
    color: #ffffff;
    font-family: 'Karla', sans-serif;
    font-size: 20px;
    margin: 0 auto;
    padding: 0;

    @media (max-width: 1199px) {
      font-size: 16px;
    }
  }

  @media (max-width: 575px) {
    zoom: 0.8;
  }
}
