@import '../../styles/effects/gradient-button';
@import '../../styles/effects/gradient-text';

.Navbar {
  backdrop-filter: blur(4px);
  background-color: rgba(46, 21, 61, .8);
  box-shadow: 0 0 100px hsla(0, 0%, 0%, .3);
  padding: 12px 0;

  .BlogButton {
    color: #ffffff !important;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      @extend .GradientText;
    }
  }

  @media screen and (max-width: 1055px) {
    padding-left: 80px;
  }

  @media screen and (max-width: 992px) {
    padding-left: 0;
  }
}

.ContactButton {
  @extend .GradientButton;
  text-transform: none !important;
  text-decoration: none;
}

.ProductMenu {
  h3 {
    color: #ffffff;
  }

  li {
    a {
      display: block;
      width: 100%;
    }

    img {
      display: block;
      height: 40px;
    }
  }
}
