@import '../../../styles/effects/gradient-button';
@import '../../../styles/effects/gradient-text';

.Hero {
  background-image: linear-gradient(35deg,
    rgb(28, 12, 34) 0%,
    rgb(34, 11, 50) 24%,
    rgb(38, 10, 61) 35%,
    rgb(40, 8, 68) 43%,
    rgb(46, 7, 80) 50%,
    rgb(49, 5, 87) 57%,
    rgb(55, 5, 77) 65%,
    rgb(65, 5, 72) 72%,
    rgb(72, 5, 62) 81%,
    rgb(77, 5, 49) 90%,
    rgb(82, 5, 33) 100%);
  box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #979797;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'NunitoSansBlack';
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    line-height: 65px;
    text-transform: uppercase;
    color: transparent;
    background-clip: text;
    animation: move 5s linear infinite;
    background-image: linear-gradient(to right, #EF3355, #C766F4, #8C66F4, #C766F4, #EF3355);
    background-size: 200% auto;

    @keyframes move {
      to {
        background-position: 200% center;
      }
    }
  }

  h3 {
    font-size: calc(max(min(28pt, 1.25vw), 18pt));
    color: #f8e3ff;
  }
}

.HeroButton {
  @extend .GradientButton;
}
