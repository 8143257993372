// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,900;1,700&display=swap');

// Vendors
@import 'bootstrap';
@import 'font-awesome';

// Slick
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import 'defaults';

// Fonts
@import 'fonts';
