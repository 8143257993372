.Robot {
  margin: 0 !important;
  padding: 0 !important;

  > div {
    margin: 0 !important;
    padding: 0 !important;
  }

  &:before {
    background: linear-gradient(0deg, #2e153d 0%, rgba(46,21,61,0) 100%);
    content: '';
    display: block;
    height: 4rem;
    position: absolute;
    top: -4rem;
    width: 100%;
  }
}

.RobotText1 {
  left: 21%;
  top: 48%;

  @media only screen and (max-width: 1399px) {
    left: 20%;
    top: 43%;
  }

  @media only screen and (max-width: 1199px) {
    left: 18%;
  }

  @media only screen and (max-width: 991px) {
    left: 14.5%;
    top: 38%;
  }

  @media only screen and (max-width: 767px) {
    left: 13%;
    top: 8%;
  }

  @media only screen and (max-width: 575px) {
    left: 4%;
    top: 4%;
  }
}

.RobotText2 {
  right: 21%;
  top: 44%;

  @media only screen and (max-width: 1399px) {
    right: 20%;
    top: 41%;
  }

  @media only screen and (max-width: 1199px) {
    right: 18%;
    top: 43%;
  }

  @media only screen and (max-width: 991px) {
    right: 15%;
    top: 38%;
  }

  @media only screen and (max-width: 767px) {
    right: 10%;
    top: 8%;
  }

  @media only screen and (max-width: 575px) {
    right: 4%;
    top: 4%;
  }
}

.RobotText3 {
  left: 28%;
  bottom: 10%;

  @media only screen and (max-width: 1399px) {
    left: 26%;
    bottom: 8%;
  }

  @media only screen and (max-width: 1199px) {
    left: 23%;
    bottom: 11%;
  }

  @media only screen and (max-width: 991px) {
    left: 19%;
    bottom: 8%;
  }

  @media only screen and (max-width: 767px) {
    left: 14%;
    bottom: 12%;
  }

  @media only screen and (max-width: 575px) {
    left: 4%;
    bottom: 7%;
  }
}

.RobotText4 {
  right: 24.5%;
  bottom: 10%;

  @media only screen and (max-width: 1399px) {
    right: 22%;
    bottom: 8%;
  }

  @media only screen and (max-width: 1199px) {
    right: 18%;
    bottom: 11%;
  }

  @media only screen and (max-width: 991px) {
    right: 11%;
    bottom: 8%;
  }

  @media only screen and (max-width: 767px) {
    right: 12%;
    bottom: 12%;
  }

  @media only screen and (max-width: 575px) {
    right: 4%;
    bottom: 7%;
  }
}
