@import '../../../styles/effects/gradient-text';

.Box {
  text-align: start;

  h3 {
    @extend .GradientText;

    font-size: 2.25rem;
    font-weight: 900;

    @media only screen and (max-width: 991px) {
      text-align: center;
    }
  }

  p {
    color: #979797;
    font-size: 1.25rem;

    @media only screen and (max-width: 991px) {
      text-align: center;
    }
  }
}
