@import '../../styles/effects/gradient-text';

.Title {
  color: #333333;
  font-size: calc(min(10vw, 38pt));
  font-weight: 900;
  text-align: center;

  span {
    @extend .GradientText;

    display: block;
  }
}

.InverseTitle {
  @extend .Title;

  color: #ffffff;
}
