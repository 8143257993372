.Footer {
  background: #442458;
  color: #ffffff;
  font-size: 16px;

  a {
    color: #a53a82;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    opacity: 0.5;

    a {
      color: inherit;
    }
  }
}
