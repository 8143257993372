.Background {
  background-color: #2e153d;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  @media only screen and (max-width: 992px) {
    background-position: top left;
    background-size: 250%;
  }
}
