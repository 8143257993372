.menuToggle {
  display: block;
  position: absolute;
  top: 21px;
  left: 25px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  transform: scale(0.8);

  @media only screen and (max-width: 991px) {
    display: none;
  }

  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;

    &:checked~span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);

      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      &:nth-last-child(2) {
        opacity: 1;
        transform: rotate(-45deg) translate(0, -1px);
      }
    }

    &:checked~ul {
      transform: none;
      opacity: 1;
    }
  }

  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      opacity 0.55s ease;

    &:first-child {
      transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }
}

.menu {
  position: absolute;
  width: 370px;
  height: 200vh;
  background-image: linear-gradient(35deg,
      rgb(28, 12, 34) 0%,
      rgb(34, 11, 50) 24%,
      rgb(38, 10, 61) 35%,
      rgb(40, 8, 68) 43%,
      rgb(46, 7, 80) 50%,
      rgb(49, 5, 87) 57%,
      rgb(55, 5, 77) 65%,
      rgb(65, 5, 72) 72%,
      rgb(72, 5, 62) 81%,
      rgb(77, 5, 49) 90%,
      rgb(82, 5, 33) 100%);
  box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);
  margin: -100px 0 0 0;
  padding: 40px 30px;
  padding-top: 125px;
  left: -50px;
  list-style-type: none;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);

  h3 {
    color: #f8e3ff;
    margin-left: 14px;
  }

  li {
    padding: 10px 0;
    font-size: 22px;
    transform: scale(0.7) translateX(-50px);

    a {
      display: block;
      width: 100%;
    }

    img {
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .menu {
    transform: none;
    opacity: 0;
    transition: opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  }
}
