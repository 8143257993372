@import '../../../styles/effects/gradient-text';

.Question {
  background: #ededed !important;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 1rem 2rem;

  i, h3 {
    @extend .GradientText;

    font-size: 1.5rem !important;
    font-weight: 800;
    margin: 0;
    padding: 0;
  }

  i {
    font-size: 1rem !important;
    margin-right: 1rem;
    padding-top: 0.4rem;
  }

  > p {
    color: #555555;
    font-size: 1.25em;
    margin: 0 0 0 1.5rem;
    padding: 0;
  }
}
