@import '../../styles/effects/gradient-text';

.Document {
  color: #000000;

  h2, h3 {
    display: inline-block;
  }

  h2, h3,
  blockquote {
    @extend .GradientText;
    text-transform: uppercase;
  }

  h2 {
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  h3 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 32px;
  }

  h4 {
    font-size: 22px;
  }

  blockquote {
    @extend .GradientText;

    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 16px;
  }

  a {
    text-decoration: underline;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  ul, ol {
    margin-bottom: 36px;
    padding-left: 16px;

    li {
      margin-bottom: 9px;
    }
  }

  ol {
    list-style-type: decimal;
    padding-left: 40px;
  }
}
