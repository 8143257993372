@import '../../../styles/effects/gradient-button';

.Contact {
  background-color: #2e153d;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Form {
  background: transparent !important;
  color: #ffffff;
  font-family: 'Karla', sans-serif;
  padding: 0;

  small {
    margin-left: 0.5rem;
    color: #bcbcbc;
  }

  a {
    color: #e93a6d;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.SubmitButton {
  @extend .GradientButton;

  display: block;
  margin: 2.5rem auto 0 auto;

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
}
