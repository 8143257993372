html,
body,
#root {
  min-height: 100vh;
}

html {
  overflow-y: scroll;
}

body {
  font-family: 'Karla', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
}

main {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Karla', sans-serif;
}

footer {
  margin-top: auto;
}
