@import '../../../styles/effects/gradient-text';

.Steps {
  color: #979797;

  h2 {
    @media only screen and (max-width: 991px) {
      text-align: center;
    }
  }

  h3 {
    @extend .GradientText;
    display: inline-block;
    font-weight: 900;
  }

  h4 {
    @media only screen and (max-width: 991px) {
      text-align: center;
    }
  }
}
