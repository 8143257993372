@import '../../../styles/effects/gradient-button';

.List {
  color: #6f6675;
  font-size: 1.125rem;
  list-style-type: none;
  width: calc(min(400px, 100%));
}

.Button {
  @extend .GradientButton;
  text-decoration: none;
}
